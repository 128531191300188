import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Alert,Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import logodark from "../../assets/images/logo-dark.png";
import { forgetUser } from '../../store/actions';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Validator from '../../component/Common/Validator';
import SimpleReactValidator from 'simple-react-validator';
import { log_in, is_logged_in, free } from '../../api';
import Swal from 'sweetalert2';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LanguageSwitcherBasic } from '../../lang';


export default injectIntl(class ForgetPassword extends Component {
    state = { step: 1 };

    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({
            messages: {
                email: 'That is not an email address.',
                default: 'This field is required.',
                in: 'Passwords does not match.',
                min: 'Minimum %d characters.'
            }
        });
    }

    handleRetry(e) {
        e.preventDefault();

        this.setState({ submitted: false, email: null });
    }

    async handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return ;
        }

        this.setState({ loading: true });
        try {
            switch(this.state.step) {
                case 1:
                    let signature = (await free.post('/reset', { email: this.state.email })).data;
                    this.setState({ step: 2, signature });
                break;
                case 2:
                    await free.get(this.state.signature, { params: { code: this.state.code } });
                    this.setState({ step: 3, password: null, password2: null });
                break;
                case 3:
                    await free.post(this.state.signature, { email: this.state.email, password: this.state.password }, { params: { code: this.state.code } });
                    await log_in(this.state.email, this.state.password);
                    this.forceUpdate();

                    this.setState({ step: 1, password: null, password2: null });
                break;
            }
        } catch(e) {
            console.error(e);
            Swal.fire('Error', 'Invalid information. Please try again.', 'error');
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { intl } = this.props;

        if(is_logged_in()) {
            return <Redirect to="/" />
        }

        return (
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8" lg="6" xl="5">
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <h3 className="text-center mt-4">
                                    <Link to="/" className="logo logo-admin"><img src={logodark} height="30" alt="logo"/></Link>
                                    </h3>
                                    <div className="p-3">
                                        <h4 className="text-muted font-size-18 mb-1 text-center"><FormattedMessage id="AUTH.FORGET_PASSWORD_TITLE" /></h4>
                                        <p className="text-muted text-center">You can reset your password in this page.</p>
                                        
                                        <Form onSubmit={this.handleSubmit.bind(this)}>
                                            <FormGroup>
                                                <Label>E-Mail Address</Label>
                                                <Validator name="email" type="required|email" controller={this}>
                                                    <Input
                                                        type="text"
                                                        disabled={this.state.loading || this.state.step !== 1}
                                                        value={this.state.email || ""}
                                                        onChange={a => this.setState({ email: a.currentTarget.value })}
                                                        placeholder={intl.formatMessage({ id: 'AUTH.EMAIL' })}
                                                    />
                                                </Validator>
                                            </FormGroup>
                                            { this.state.step == 2 &&
                                                <>
                                                    <FormGroup>
                                                        <Label><FormattedMessage id="AUTH.CODE" /></Label>
                                                        <Validator name="code" type="required" controller={this}>
                                                            <Input
                                                                type="text"
                                                                value={this.state.code || ""}
                                                                onChange={a => this.setState({ code: a.currentTarget.value })}
                                                                placeholder={intl.formatMessage({ id: 'AUTH.CODE' })}
                                                                disabled={this.state.loading}
                                                            />
                                                        </Validator>
                                                    </FormGroup>
                                                    <p className="text-left font-14">
                                                        <FormattedMessage
                                                            id="AUTH.ENTER_CODE"
                                                            values={{
                                                                email: <b>{this.state.email}</b>
                                                            }}
                                                        />
                                                    </p>
                                                    <p className="text-left font-14">
                                                        <FormattedMessage
                                                            id="AUTH.USE_DIFFERENT_EMAIL"
                                                            values={{
                                                                a: text => <a href="#" onClick={this.handleRetry.bind(this)}>{text}</a>
                                                            }}
                                                        />
                                                    </p>
                                                </>}
                                            { this.state.step == 3 &&
                                                <>
                                                    <FormGroup>
                                                        <Label><FormattedMessage id="AUTH.NEW_PASSWORD" /></Label>
                                                        <Validator name="password" type="required|min:6" controller={this}>
                                                            <Input
                                                                type="password"
                                                                value={this.state.password || ""}
                                                                onChange={a => this.setState({ password: a.currentTarget.value })}
                                                                placeholder={intl.formatMessage({ id: 'AUTH.NEW_PASSWORD' })}
                                                                disabled={this.state.loading}
                                                                autoComplete="new-password"
                                                            />
                                                        </Validator>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label><FormattedMessage id="AUTH.NEW_PASSWORD2" /></Label>
                                                        <Validator name="password2" type={"required|in:" + this.state.password} controller={this}>
                                                            <Input
                                                                type="password"
                                                                value={this.state.password2 || ""}
                                                                onChange={a => this.setState({ password2: a.currentTarget.value })}
                                                                placeholder={intl.formatMessage({ id: 'AUTH.NEW_PASSWORD2' })}
                                                                disabled={this.state.loading}
                                                                autoComplete="new-password"
                                                            />
                                                        </Validator>
                                                    </FormGroup>
                                                </>}
                                            <Button block size="lg" color="success" className="font-18" disabled={this.state.loading}>
                                                { (this.state.loading && <i className="mdi mdi-loading mdi-spin mr-1" />) || <i className="mdi mdi-login mr-1" />}
                                                <FormattedMessage id="AUTH.CONTINUE" />
                                            </Button>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <LanguageSwitcherBasic />
                            <div className="mt-5 text-center">
                                <Link to="/login" className="font-14" href="#"><FormattedMessage id="AUTH.LOGIN_INSTEAD" /></Link>
                                <p>© 2022 Elcamp</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
});
