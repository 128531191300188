
import React, {useState, useContext} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { SetBreadcrumb } from '../Category';

const CardStateContext = React.createContext();

function AutoCard({ children }) {
  const state = useState(false);

  return <CardStateContext.Provider value={state}><Card className="mb-3">{children}</Card></CardStateContext.Provider>;
}

function AutoCardHeader({ children }) {
  const [ isOpen, setOpen ] = useContext(CardStateContext);

  return <CardHeader onClick={a => setOpen(!isOpen)} style={{ cursor: 'pointer' }}>
    <Row>
      <Col>
        {children}
      </Col>
      <Col md="auto" className="d-flex align-items-center">
        <i className={"feather icon-chevron-" + (isOpen ? "down" : 'right')}></i>
      </Col>
    </Row>
  </CardHeader>;
}

function AutoCardBody({ children }) {
  const [ isOpen ] = useContext(CardStateContext);

  return !isOpen ? null : <CardBody>{children}</CardBody>;
}

export default function Info() {
  const intl = useIntl();
  const email = intl.formatMessage({ id: 'SUPPORT.EMAIL' })

  return <>
    <SetBreadcrumb title={intl.formatMessage({ id: 'INFO.TITLE' })}
        items={[
            {
                title: intl.formatMessage({ id: 'INFO.TITLE' }),
                href: '/intro'
            }
        ]}
    />
    <FormattedMessage
        id="INFO.DESCRIBE"
        values={{
        a: text => <AutoCard>{text}</AutoCard>,
        b: text => <AutoCardHeader><b>{text}</b></AutoCardHeader>,
        e: text => <AutoCardBody>{text}</AutoCardBody>,
        c: text => <b>{text}</b>,
        d: text => <p>{text}</p>,
        br: <br />,
        email: <a href={"mailto:" + email}>{email}</a>
        }}
    />
    </>;
}
