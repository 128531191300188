
import React, { useCallback, useMemo } from 'react';
import FilePickerCore from "./FilePickerCore";
import {useUploadController} from "./Upload";

export default function SingleFilePicker({ disabled, className, accepts, value, onChange, transform, children }) {
    const uploadController = useUploadController();
    const controller = useMemo(() => uploadController(value), [uploadController, value]);

    const _onChange = useCallback(function() {
        if (controller.$state === 3) {
            if (value !== controller) {
                onChange(controller);
            }
        } else {
            if (!!value) {
                onChange(null);
            }
        }
    }, [ value, controller, onChange ]);

    return <FilePickerCore
        disabled={!!disabled}
        className={className}
        accepts={accepts}
        value={controller}
        onNotify={_onChange}
        transform={transform}
        >{children}</FilePickerCore>;
}
