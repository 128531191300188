import React from 'react';
import {Button, FormGroup, Row, Col, Label, Input} from "reactstrap";
import {createContext, useContext} from "react";
import {Validator} from "../../component/Validator";

export function Question({ title, children }) {
    return <div className="question">
        <h1>{title}</h1>
        { children }
    </div>
}

const OptionIsCheckedContext = createContext(false);
const OptionOnClickContext = createContext(_ => {});

export function Options({ value, onChange, children }) {
    const values = value?.split("; ") || [];
    const other = values.find(value => value?.indexOf("Other: ") === 0);
    const isOther = !!other;

    console.log(values);

    return <>
        <div className="options">
            { React.Children.map(children, a => <OptionIsCheckedContext.Provider value={values.indexOf(a.props.title) !== -1 || (a.props.isOther && isOther)}>
                <OptionOnClickContext.Provider value={e => {
                    e.preventDefault();
                    onChange((a.props.isOther ? isOther ? values.filter(b => b !== other) : values.concat(["Other: "]) : values.indexOf(a.props.title) === -1 ? values.concat([a.props.title]) : values.filter(b => b !== a.props.title)).join("; "));
                }}>
                    {a}
                </OptionOnClickContext.Provider>
            </OptionIsCheckedContext.Provider>) }
        </div>
        { isOther && <Row className="mt-3">
            <Col>
                <FormGroup>
                    <Label>Please specify:</Label>
                    <Validator name={children[0].props.title} type="required">
                        <Input type="text"
                            value={other?.substring("Other: ".length) || ''}
                            onChange={a => onChange(values.map(b => b === other ? "Other: " + a.currentTarget.value : b).join("; "))}
                        />
                    </Validator>
                </FormGroup>
            </Col>
        </Row> }
    </>;
}


export function OptionsSingle({ value, onChange, children }) {
    const isOther = value?.indexOf("Other: ") === 0;

    return <>
    <div className="options">
        { React.Children.map(children, a => <OptionIsCheckedContext.Provider value={a.props.title === value || (a.props.isOther && isOther)}>
            <OptionOnClickContext.Provider value={e => {
                e.preventDefault();
                onChange(a.props.isOther ? 'Other: ' : a.props.title === value ? null : a.props.title);
            }}>
                {a}
            </OptionOnClickContext.Provider>
        </OptionIsCheckedContext.Provider>) }
    </div>
    { isOther && <Row className="mt-3">
        <Col>
            <FormGroup>
                <Label>Please specify:</Label>
                <Validator name={children[0].props.title} type="required">
                    <Input type="text"
                        value={value.substring("Other: ".length) || ''}
                        onChange={a => onChange("Other: " + a.currentTarget.value)}
                    />
                </Validator>
            </FormGroup>
        </Col>
    </Row> }
    </>;
}

export function Option({ title, isOther }) {
    const isChecked = useContext(OptionIsCheckedContext);
    const onClick = useContext(OptionOnClickContext);

    return <Button type="button" outline={!isChecked} color="secondary" onClick={onClick}>
        {isOther ? (title || "Other") + "Other, please specify" : title}
    </Button>;
}
