
import React, { useContext, useMemo } from 'react';
import UploadControllerFactory from 'upload-controller-factory';

const UploadContext = React.createContext(null);
const PreviewContext = React.createContext(null);

export function useUploadController() {
    return useContext(UploadContext);
}

export function usePreviewComponent() {
    return useContext(PreviewContext);
}

export default function UploadProvider({ uploader, preview, fields, children }) {
    const uploadController = useMemo(() => {
        const obj = UploadControllerFactory({
            uploader,
            fields
        });

        obj.uploader = uploader;
        return obj;
    }, [uploader, fields]);

    return <UploadContext.Provider value={uploadController}>
        <PreviewContext.Provider value={preview}>
            { children }
        </PreviewContext.Provider>
    </UploadContext.Provider>;
}
