import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {UserContext} from "../../UserProvider";
import useFetch from "../../fetch";
import {endpoint, free, tokenized} from '../../api';

function useEventSource(url) {
    const [ ev, setEv ] = useState();

    useEffect(function() {
        if (url) {
            const _ev = new EventSource(url);
            setEv(_ev);
            return function() {
                _ev.close();
            };
        }
    }, [url, setEv]);

    return ev;
}

const EventSourceContext = React.createContext();

function EventSourceProvider({ children, url }) {
    const ev = useEventSource(url);
    return <EventSourceContext.Provider value={ev}>
        { children }
    </EventSourceContext.Provider>;
}

export function ChatWrapper({ children }) {
    const [ ticket ] = useFetch('/message/ticket');

    if (!ticket)
        return null;

    return <EventSourceProvider url={endpoint.replace(/\/$/, '') + ticket}>
        {children}
    </EventSourceProvider>
}

function useEventMessageEffect(id, cb, deps) {
    const handler = useCallback(cb, deps);
    const ev = useContext(EventSourceContext);

    useEffect(function() {
        if (ev && handler) {
            ev.addEventListener(id, handler);

            return function() {
                ev.removeEventListener(id, handler);
            };
        }
    }, [handler, ev, id]);
}

export default function useChat() {
    const user = useContext(UserContext);
    const [ messages, setMessages ] = useState(null);

    useEffect(function() {
        async function update() {
            const res = await tokenized.get('/message');
            setMessages(res.data);
        }

        update();
        }, [setMessages]);

    useEventMessageEffect('message', function(e) {
        var message = JSON.parse(e.data);
        console.log(message);
        const relatedUser = message.from.id === user.id ? message.to : message.from;

        setMessages(a => {
            const _relatedUser = a.find(b => relatedUser.id === b.user.id);
            console.log(_relatedUser);

            if (_relatedUser) {
                return a.map(b => relatedUser.id === b.user.id ? ({
                    ...b,
                    messages: [
                        ...b.messages,
                        message
                    ]
                }) : b);
            } else {
                return [
                    {
                        user: relatedUser,
                        messages: [
                            message
                        ]
                    },
                    ...a
                ]
            }
        });
        }, [user, setMessages]);

    return messages;
}
