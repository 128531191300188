import {
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    Container,
    FormGroup,
    Label,
    Input,
    CardFooter,
    Button,
    CustomInput
} from "reactstrap";
import {Question, Option, Options} from "./Utils";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {tokenized} from "../../api";
import { Redirect } from 'react-router-dom';
import {MainContext, UserContext} from "../../UserProvider";
import {AvatarChooser} from "../../component/Common/UserInfo";
import {Validator} from "../../component/Validator";
import {ValidatorProvider} from "../../component/ValidatorProvider";
import ImagePicker from "../../component/Common/ImagePicker";

export default function Signup() {
    const user2 = useContext(UserContext);
    const [ user, setUser ] = useState();
    const userContext = useContext(UserContext);
    const mainContext = useContext(MainContext);

    const updateUser = useCallback((e) => {
        e.preventDefault();

        mainContext
            .loading(async () => {
                const res = await tokenized.patch('/user/' + userContext.id, user);
                setUser(res.data);
                await userContext.update();
            });
        }, [user, userContext, mainContext]);

    useEffect(() => {
        async function updateUser() {
            setUser((await tokenized.get('/user/me')).data);
        }

        updateUser();
    }, []);

    if (!user)
        return null;

    if (user2.metadata)
        return <Redirect to="/network/networking-hub" />;

    return <ValidatorProvider>
        <form onSubmit={updateUser}>
            <Container>
                <Card>
                    <CardHeader>
                        <h2>Networking Hub and Mentoring Application Form</h2>
                    </CardHeader>
                    <CardBody>
                        <NetworkQuestionare value={user.metadata} onChange={metadata => setUser({ ...user, metadata })} />
                        <UserInfo user={user} setUser={setUser} />
                    </CardBody>
                    <CardFooter className="text-right">
                        <Button type="submit" color="primary"><i className="fa fa-save mr-1" /> <FormattedMessage id="PROFILE.SAVE" /></Button>
                    </CardFooter>
                </Card>
            </Container>
        </form>
    </ValidatorProvider>;
}

export function NetworkQuestionare({ value, onChange }) {
    return <div className="questionare">
        <Question title="Who are you?">
            <Options value={value?.who_are_you} onChange={a => onChange({ ...(value || {}), who_are_you: a })}>
                <Option title="Athlete" />
                <Option title="Entrepreneur" />
                <Option title="Investor" />
                <Option title="Mentor" />
                <Option title="Expert" />
                <Option title="Technology Specialist" />
                <Option isOther />
            </Options>
        </Question>
        <Question title="Which organisation?">
            <Options value={value?.organisation} onChange={a => onChange({ ...(value || {}), organisation: a })}>
                <Option title="Startup" />
                <Option title="SME" />
                <Option title="Large Entity" />
                <Option title="Sport Entity" />
                <Option title="Academic" />
                <Option title="No Organisation" />
                <Option title="Government" />
                <Option isOther />
            </Options>
        </Question>

        <Question title="What are you looking for?">
            <Options value={value?.what_are_you_looking_for} onChange={a => onChange({ ...(value || {}), what_are_you_looking_for: a })}>
                <Option title="A co-founder / team member" />
                <Option title="An investor" />
                <Option title="A business mentor" />
                <Option title="A technology expert" />
                <Option title="A bussiness expert" />
                <Option isOther />
            </Options>
        </Question>

        <Question title="What knowledge, competence or expertise can you share or offer?">
            <Options value={value?.knowledge} onChange={a => onChange({ ...(value || {}), knowledge: a })}>
                <Option title="Activity & Performance" />
                <Option title="Fans & Content" />
                <Option title="Management & Organisation" />
                <Option title="Expertise in a particular area or sport" isOther />
                <Option title="Entrepreneurship" />
                <Option title="Innovation Management" />
            </Options>
        </Question>

        <CustomInput
            id="is_mentor"
            type="checkbox"
            className="my-3"
            label="Would you like to be a mentor?"
            checked={value?.type === "MENTOR"}
            onChange={() => onChange({ ...(value || {}), type: value?.type === "MENTOR" ? "" : "MENTOR" })}
        />
    </div>;
}

export function UserInfo({ user, setUser, validator }) {
    return <>
        <FormGroup>
            <Label><FormattedMessage id="AVATAR" /></Label>
            <Validator name="avatar">
                <ImagePicker
                    width="256"
                    height="256"
                    value={user.avatar}
                    onChange={avatar => setUser({ ...user, avatar })}
                    type="png"
                />
            </Validator>
        </FormGroup>

        <FormGroup>
            <Label><FormattedMessage id="AUTH.NAME" /></Label>
            <Validator
                name="first_name"
                type="required">
                <Input type="text"
                    value={user.name}
                    onChange={a => setUser({ ...user, name: a.currentTarget.value  } )}
                />
            </Validator>
        </FormGroup>
        <FormGroup>
            <Label><FormattedMessage id="AUTH.EMAIL" /></Label>
            <Validator
                name="email"
                type="required|email">
                <Input type="text"
                    value={user.email}
                    onChange={a => setUser({ ...user, email: a.currentTarget.value  } )}
                />
            </Validator>
        </FormGroup>
        <FormGroup>
            <Label>Linkedin Profile</Label>
            <Validator name="linkedin_profile_url" type="url">
                <Input type="text"
                    value={user.linkedin_profile_url}
                    onChange={a => setUser({ ...user, linkedin_profile_url: a.currentTarget.value  } )}
                />
            </Validator>
        </FormGroup>
        <Row>
            <Col>
                <FormGroup>
                    <Label>Country</Label>
                    <Validator name="country">
                        <Input type="text"
                            value={user.country}
                            onChange={a => setUser({ ...user, country: a.currentTarget.value  } )}
                        />
                    </Validator>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label>City</Label>
                    <Validator name="country">
                        <Input type="text"
                            value={user.city}
                            onChange={a => setUser({ ...user, city: a.currentTarget.value  } )}
                        />
                    </Validator>
                </FormGroup>
            </Col>
        </Row>
        <FormGroup>
            <Label>About</Label>
            <Validator name="about">
                <Input type="textarea"
                    rows={5}
                    value={user.bio}
                    onChange={a => setUser({ ...user, bio: a.currentTarget.value  } )}
                />
            </Validator>
        </FormGroup>

        <CustomInput
            id="share_for_eu"
            type="checkbox"
            label={<>
            <p><b>We are writing to request your consent to use your personal information on other platforms that have been prepared by the Collective Innovation AS.</b> By providing your consent, you agree that your personal data may be shared with these platforms for the purposes of personalizing your user experience, recommending content tailored to your interests, inform and offer new services, conducting research, and enhancing platform security.</p>

                <p><b>Please note that your personal information will be processed in accordance with GDPR regulations, and you have the right to withdraw your consent at any time.</b> You also have the right to access, rectify, erase, or restrict the processing of your personal information.</p>

                <p>If you agree to this request, please click 'I agree' below. If you do not wish to grant consent, please do not click 'I agree' and your personal information will not be shared with these platforms.</p>

                <p>Thank you for your attention and cooperation</p>
            </>}
            checked={!!user.share_for_eu}
            onChange={() => setUser({ ...user, share_for_eu: !user.share_for_eu })}
        />
    </>;
}
