import { useCallback } from 'react';
import * as SparkMD5 from 'spark-md5';
import UploadProvider from "./Common/Upload";
import {free, tokenized} from "../api";

function computeChecksumMd5(file) {
    return new Promise((resolve, reject) => {
        const chunkSize = 2097152; // Read in chunks of 2MB
        const spark = new SparkMD5.ArrayBuffer();
        const fileReader = new FileReader();

        let cursor = 0; // current cursor in file

        fileReader.onerror = function() {
            reject('MD5 computation failed - error reading the file');
        };

        function processChunk(chunk_start) {
            const chunk_end = Math.min(file.size, chunk_start + chunkSize);
            fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
        }

        fileReader.onload = function(e) {
            spark.append(fileReader.result);
            cursor += chunkSize;

            if (cursor < file.size) {
                processChunk(cursor);
            } else {
                resolve(btoa(spark.end(true)));
            }
        };

        processChunk(0);
    });
}

const fields = ['id', 'access_url', 'name', 'size', 'video'];

export default function UploadConfig({ children }) {
    const uploader = useCallback(async function(name, blob, contentType, abort, progress) {
        try {
            const hash = await computeChecksumMd5(blob);

            const ticketResponse = await tokenized.put('/file/' + encodeURIComponent(name) + '/start?hash=' + encodeURIComponent(hash));
            console.log(ticketResponse);
            if (ticketResponse.status === 201) {
                return ticketResponse.data;
            }

            const ticket = ticketResponse.data;
            await free.put(ticket.upload_url, blob, {
                headers: {
                    'Content-Type': contentType,
                    'x-ms-date': (new Date()).toGMTString(),
                    'x-ms-version': '2020-04-08',
                    'x-ms-blob-type': 'BlockBlob'
                },
                onUploadProgress: function(progressEvent) {
                    progress(progressEvent.loaded / progressEvent.total);
                },
                signal: abort
            });

            let data = await tokenized.delete(ticket.complete_url);

            return data.data;
        } catch (e) {
            throw e;
        }
    }, [  ]);

    return <UploadProvider uploader={uploader} fields={fields}>
        { children }
    </UploadProvider>
}