import React, {useCallback, useContext, useEffect, useReducer, useRef, useState} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, FormGroup, Label, Input, CardFooter, Button } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { tokenized } from "../api";
import Validator from '../component/Common/Validator';
import { MainContext, UserContext } from '../UserProvider';
import {ValidatorProvider} from "../component/ValidatorProvider";
import {NetworkQuestionare, UserInfo} from "../module/Network/Signup";

function ResetPassword() {
  const intl = useIntl();
  const user = useContext(UserContext);
  const mainContext = useContext(MainContext);
  const validator = useRef(new SimpleReactValidator({
    messages: {
      email: intl.formatMessage({ id: 'VALIDATION.EMAIL' }),
      in: intl.formatMessage({ id: 'PASSWORD.NOT_MATCH' }),
      default: intl.formatMessage({ id: 'VALIDATION.REQUIRED' })
    }
  }));

  const [ _, forceUpdate ] = useReducer(x => x + 1, 0);

  const [ currentPassword, setCurrentPassword ] = useState();
  const [ newPassword, setNewPassword ] = useState();
  const [ newPassword2, setNewPassword2 ] = useState();

  const hasPassword = !!user.password_updated_at;

  const updatePassword = useCallback((e) => {
    e.preventDefault();

    if (!validator.current.allValid()) {
        validator.current.showMessages();
        forceUpdate();
        return ;
    }
    
    mainContext
        .loading(async () => {
          await tokenized.post('/user/me/password', { current_password: currentPassword, new_password: newPassword });

          setCurrentPassword('');
          setNewPassword('');
          setNewPassword2('');
        });
  }, [currentPassword, newPassword, mainContext]);

  return <form onSubmit={updatePassword}>
      <Card>
      <CardHeader>
        <CardTitle><b><FormattedMessage id={hasPassword ? "PASSWORD.CHANGE" : "PASSWORD.SET"} /></b></CardTitle>
      </CardHeader>
      <CardBody>
        { hasPassword ?
          <FormGroup>
            <Label><FormattedMessage id="PASSWORD.CURRENT" /></Label>
            <Validator name="current_password" value={currentPassword} type="required" validator={validator}>
              <Input type="password"
                value={currentPassword || ''}
                onChange={a => setCurrentPassword(a.currentTarget.value)}
                autoComplete="current-password"
              />
            </Validator>
          </FormGroup>
        : <p>
          <FormattedMessage id="PASSWORD.SET.NOTICE" values={{ email: <b>{user.email}</b>, br: <br /> }} />
        </p>}

        <FormGroup>
          <Label><FormattedMessage id="PASSWORD.NEW" /></Label>
          <Validator name="password" value={newPassword} type="required" validator={validator}>
            <Input type="password"
              value={newPassword || ''}
              onChange={a => setNewPassword(a.currentTarget.value)}
              autoComplete="new-password"
            />
          </Validator>
        </FormGroup>
        <FormGroup>
          <Label><FormattedMessage id="PASSWORD.NEW_AGAIN" /></Label>
          <Validator name="password2" value={newPassword2} type={["required", { in: [ newPassword ] }]} validator={validator}>
            <Input type="password"
              value={newPassword2 || ''}
              onChange={a => setNewPassword2(a.currentTarget.value)}
              autoComplete="new-password"
            />
          </Validator>
        </FormGroup>
      </CardBody>
      <CardFooter className="text-right">
        <Button color="primary"><i className="fa fa-save mr-1" /> <FormattedMessage id={hasPassword ? "PASSWORD.SAVE" : "PASSWORD.SET.SAVE"} /></Button>
      </CardFooter>
    </Card>
  </form>;
}

function UserProfileEdit() {
    const [ user, setUser ] = useState();
    const userContext = useContext(UserContext);
    const mainContext = useContext(MainContext);

    const updateUser = useCallback((e) => {
        e.preventDefault();

        mainContext
            .loading(async () => {
                const res = await tokenized.patch('/user/' + userContext.id, user);
                setUser(res.data);
                await userContext.update();
            });
        }, [user, userContext, mainContext]);

    useEffect(() => {
        async function updateUser() {
            setUser((await tokenized.get('/user/me')).data);
        }

        updateUser();
    }, []);

    if (!user)
        return null;

    return <ValidatorProvider>
        <form onSubmit={updateUser}>
            <Card>
                <CardHeader>
                    Edit Profile
                </CardHeader>
                <CardBody>
                    <UserInfo user={user} setUser={setUser} />
                    { user.metadata && <NetworkQuestionare value={user.metadata} onChange={metadata => setUser({ ...user, metadata })} /> }
                </CardBody>
                <CardFooter>
                    <Row>
                        <Col>
                            <Button color="secondary">Update Profile</Button>
                        </Col>
                        { user.metadata && <Col className="col-auto align-self-end">
                            <Button type="button" color="danger" outline onClick={() => setUser({ ...user, metadata: null })}>Remove Network Profile</Button>
                        </Col> }
                    </Row>
                </CardFooter>
            </Card>
        </form>
    </ValidatorProvider>;
}

export default class extends React.Component {
  render() {
    return <Row>
      <Col md="8">
          <UserProfileEdit />
      </Col>
      <Col>
        <ResetPassword />
      </Col>
    </Row>;
  }
}