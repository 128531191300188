import {Link, useParams} from "react-router-dom";
import useFetch from "../../fetch";
import {Row, Col, Card, CardBody, Container} from "reactstrap";
import {Avatar} from "../Chat/Chat";

export default function HubProfile() {
    const { id } = useParams();
    const [ user ] = useFetch('/user/' + id);

    if (!user)
        return null;

    return <Container className="hub-profile">
        <Card>
            <CardBody>
                <div className="hub-profile-header">
                    <Avatar user={user} />
                </div>
                <Row>
                    <Col>
                        <h1>{ user.name }</h1>
                        <h2>{ user.metadata.who_are_you } / { user.metadata.organisation }</h2>
                    </Col>
                    <Col>
                        <span>
                            <i className="mdi mdi-pin mr-2" />
                            { user.country }/{ user.city }
                        </span>
                        <a href={"mailto:" + user.email}>
                            <i className="mdi mdi-mail mr-2" />
                            { user.email }
                        </a>
                    </Col>
                    <Col>
                        <a href={user.linkedin_profile_url} rel="noreferrer" target="_blank">
                            <i className="mdi mdi-linkedin-box mr-2" />
                            Linkedin Profile
                        </a>
                        <Link to={"/chat/" + user.id}>
                            <i className="mdi mdi-message-text-outline mr-2" />
                            Send message
                        </Link>
                    </Col>
                </Row>
                <div className="grid-container">
                    <div>
                        <h1>About</h1>
                        <p>{ user.bio }</p>
                    </div>
                    { user.metadata.who_are_you && <div>
                        <h1>Who are you?</h1>
                        <p>{ user.metadata.who_are_you }</p>
                    </div> }
                    { user.metadata.organisation && <div>
                        <h1>Which organisation?</h1>
                        <p>{ user.metadata.organisation }</p>
                    </div> }
                    { user.metadata.what_are_you_looking_for && <div>
                        <h1>What are you looking for?</h1>
                        <p>{ user.metadata.what_are_you_looking_for }</p>
                    </div> }
                    { user.metadata.knowledge && <div>
                        <h1>What knowledge, competence or expertise can you share or offer?</h1>
                        <p>{ user.metadata.knowledge }</p>
                    </div> }
                </div>
            </CardBody>
        </Card>
    </Container>;
}
