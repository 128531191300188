import {useCallback, useContext, useState} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, Route, Switch, useParams } from "react-router-dom";
import { Row } from "reactstrap";
import { Col } from "reactstrap";
import { Card } from "reactstrap";
import { Input } from "reactstrap";
import useChat, { ChatWrapper } from "./ChatProvider";
import {UserContext} from "../../UserProvider";
import {tokenized} from "../../api";
import useFetch from "../../fetch";
import BlankProfile from "../../assets/images/blank_profile.png";

export function Avatar({ user, className }) {
    return <img
        className={className || "avatar avatar-chat"}
        src={user.avatar?.access_url ? user.avatar?.access_url : BlankProfile}
        alt={ user.name }
    />;
}

function getTimePart(date) {
    date = new Date(date);
    return date.getHours() + ":" + date.getMinutes();
}

function UserTypeText({ user }) {
    const key = user.metadata == null ? 'FORMER_NETWORK_USER' : user.metadata?.type === "MENTOR" ? 'MENTOR' : 'NETWORKING_HUB';

    return <FormattedMessage id={key} />;
}

function User({value, isActive, onClick}) {
    return <Link className={"list-group-item list-group-item-action border-0" + (isActive ? ' chat-active' : '')} to={"/chat/" + value.user.id}>
        <div className="d-flex align-items-start">
            <Avatar user={value.user} />
            <div className="flex-grow-1 ms-3 ml-2">
                {value.user.name}
                <div className="small"><UserTypeText user={value.user} /></div>
            </div>
        </div>
    </Link>;
}

function Message({ msg }) {
    const user = useContext(UserContext);

    return <div className={"chat-message-" + (msg.to.id === user.id ? 'left' : 'right') + " pt-4"}>
        <div>
            <div className="text-muted small text-nowrap mt-2 text-center">{getTimePart(msg.created_at)}</div>
        </div>
        <div className="d-flex align-items-center">
            <Avatar user={msg.from} />
            <div className={"ml-2 flex-shrink-1 bg-light rounded py-2 px-3 " + (msg.is_user ? ' ms-3' : 'me-3')}>
                <div className="font-weight-bold mb-1">{msg.from.name}</div>
                {msg.body}
                { msg.attachments.length > 0 && <div className="mt-3">
                    <b>Attachments:</b>
                    <ul className="my-0">
                        {msg.attachments.map(a => <li key={a.id}>
                            {/*<Relative>
                            <a href={a.access_url} title={a.name} target="_blank">{a.name}</a>
                        </Relative>*/}
                        </li>)}
                    </ul>
            </div> }
        </div>
        </div>
    </div>;
}

function ChatBox({value}) {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const intl = useIntl();
    const user = useContext(UserContext);

    const send = useCallback(async function (e) {
        e.preventDefault();

        if (!message)
            return ;

        setLoading(true);
        try {
            await tokenized.put('/user/' + value.user.id + '/message', { body: message, attachments });
            setMessage('');
            setAttachments([]);
        } finally {
            setLoading(false);
        }
    }, [value, message, setMessage, setLoading, attachments, setAttachments]);

    if (!value)
        return <div className="m-5">
            Please select an user.
    </div>;

    return <>
    <div className="py-2 px-4 border-bottom d-none d-lg-block">
        <div className="d-flex align-items-center py-1">
            <div className="position-relative">
                <Avatar user={value.user} />
            </div>
            <div className="flex-grow-1 ps-3 ml-2">
                <strong>{value.user.name}</strong>
                <div className="text-muted small"><em><UserTypeText user={value.user} /></em></div>
            </div>
        </div>
    </div>
    <div className="position-relative flex-full">
        <div className="chat-messages p-4 flex-full">
            {[...value.messages].reverse().map(msg => <Message key={msg.id} msg={msg} />)}
        </div>
    </div>
    <div className="flex-grow-0 py-3 px-4 border-top">
        <form onSubmit={send}>
            <div className="input-group">
                <Input type="text" disabled={loading} placeholder={intl.formatMessage({ id: 'TYPE_MESSAGE' })} value={message || ''} onChange={a => setMessage(a.currentTarget.value)}/>
                <button type="submit" disabled={loading} className="btn btn-primary ml-2"><FormattedMessage id="SEND" /></button>
            </div>
            <div className="mt-2">
                {/*<MultiFilePicker
                    accepts="* / *"
                    value={attachments}
                    onChange={a => setAttachments(a)}
                />*/}
            </div>
        </form>
    </div>
    </>;
}

function MessagesI() {
    const messages = useChat();
    const intl = useIntl();

    const { id } = useParams();
    const activeUserId = id;

    const activeUserLocal = messages && (!activeUserId ? messages[0] : messages.find(b => b.user.id == activeUserId));
    const [ activeUserRemote ] = useFetch(id && messages && !activeUserLocal && ('/user/' + activeUserId));

    const activeUser = messages  && (activeUserLocal || (activeUserRemote && { user: activeUserRemote, messages: [] }));

    const [search, setSearch] = useState();

    if (!messages)
        return null;

    if (!activeUser && id)
        return null;

    const filteredBids = search ? messages
		.filter(a => a.user.name.indexOf(search) !== -1) : messages;

    return <>
    <Card className="flex-full">
        <Row className="no-gutters flex-grow-1">
            <Col size="12" lg="5" xl="3" className="border-right">
                <div className="px-4 d-none d-md-block">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <Input
                                type="text"
                                className="my-3"
                                placeholder={intl.formatMessage({ id: 'SEARCH'})}
                                value={search}
                                onChange={a => setSearch(a.currentTarget.value)}
                            />
                        </div>
                    </div>
                </div>
                {filteredBids.map(a => <User
                    key={a.user.id}
                    value={a}
                    isActive={a === activeUser}
                />)}
            </Col>
            <Col size="12" lg="7" xl="9" className="flex-full">
                <ChatBox value={activeUser}/>
            </Col>
        </Row>
    </Card>
    </>;
}

export default function Messages() {
    return <ChatWrapper>
        <Switch>
            <Route path="/chat/:id" component={MessagesI} />
            <MessagesI />
        </Switch>
    </ChatWrapper>;
}
