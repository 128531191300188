
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { tokenized, wrap } from './api';
import Exam from './module/Exam';
import Video, { AutoPlayHls } from './module/Video';
import ExternalReading from './module/ExternalReading';
import LearningModule from './module/LearningModule';


import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "./store/actions";
import ExternalVideo from './module/ExternalVideo';
import { TrackContext } from './Track';
import { UserContext } from './UserProvider';
import { Alert, Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function* get_parents(category) {
    while (category) {
        yield category;
        category = category.parent;
    }
}
export function getModuleName(category) {
    return [...get_parents(category)].reverse().map(a => a.name).join(" / ");
}

class _SetBreadcrumb extends React.Component {
    componentDidMount() {
        this.props.setBreadcrumbItems(this.props.title, this.props.items);
    }

    render() {
        return null;
    }
}

export const SetBreadcrumb = connect(null, { setBreadcrumbItems })(_SetBreadcrumb);

function* all_parents(category) {
    yield category;

    if(category.parent) {
        yield* all_parents(category.parent);
    }
}


class ExternalContent extends React.Component { 
    render() {
        const { title, link } = this.props;

        return <Card>
            <CardHeader>
                <CardTitle>
                    <b><FormattedMessage id="ER" /></b>
                </CardTitle>
            </CardHeader>
            <CardBody>
                <p className="mt-3 mb-5"><FormattedMessage id="ER.DOWNLOAD_DESC" /></p>
                <a href={link} target="_blank" className="btn btn-primary" onClick={this.props.onFinish}>{ title }</a>
            </CardBody>
        </Card>;
    }
}
class Content extends React.Component {
    render() {
        const { value } = this.props;

        return <Card>
            <CardHeader>
                <CardTitle>
                    <b><FormattedMessage id="RD" /></b>
                </CardTitle>
            </CardHeader>
            <CardBody>
                <p className="mt-3 mb-5"><FormattedMessage id="RD.DOWNLOAD_DESC" /></p>
                <a href={wrap(value.access_url)} target="_blank" className="btn btn-primary" onClick={this.props.onFinish}><FormattedMessage id="DOWNLOAD" /></a>
            </CardBody>
        </Card>;
    }
}

class IconButton extends React.Component {
    render() {
        return <Button size="sm" color={this.props.color} outline={this.props.outline}>
            <i class={"icon-big fa " + this.props.icon} />
            { this.props.children }
        </Button>;
    }
}
function BadgeToast({ category_id, role }) {
    const badge = role.badge;

    const hls = (badge && badge.video && badge.video.hls) || (role.last_category && role.last_category.dashboard_video && role.last_category && role.last_category.dashboard_video.hls);

    return <div>
        { (hls && <div>
            <AutoPlayHls link={hls} />
        </div>) }
        <div className="text-toast text-center">
            {
                badge ? 
                    <FormattedMessage id="NOTIFICATION.BADGE_EARN" values={{
                        name: <b>{role.name}</b>,
                        badge: <b>{badge.name}</b>
                    }} /> :
                (role.last_category && (role.last_category.id === category_id || !role.last_category.dashboard_video)) ?
                    ( role.score_gain > 0 ? <FormattedMessage id="NOTIFICATION.TASK_COMPLETE_XP" values={{
                        name: <b>{role.name}</b>,
                        score: <b>{role.score_gain}</b>
                    }} /> : <FormattedMessage id="NOTIFICATION.TASK_COMPLETE" values={{
                        name: <b>{role.name}</b>
                    }} />) : <FormattedMessage id="NOTIFICATION.MODULE_COMPLETE" values={{
                        name: <b>{role.name}</b>
                    }} />
            }
        </div>
        <div className="my-3 text-center">
            <Link to="/"><IconButton color="primary" icon="fa-dashboard" outline={!!role.next_category}><FormattedMessage id="DASHBOARD" /></IconButton></Link>{' '}
            { role.next_category && <><Link to={"/category/" + role.next_category.id}><IconButton color="primary" icon="fa-angle-double-right"><FormattedMessage id="DASHBOARD.NEXT_TASK" /></IconButton></Link>{' '}</> }
            <Button size="sm" color="danger" outline><FormattedMessage id="CLOSE" /></Button>
        </div>
    </div>;
}

function Category({ id }) {
    const [ category, setCategory ] = useState();
    const track = useContext(TrackContext);
    const user = useContext(UserContext);
    const { next_category, completed, updateByData } = track;

    const onFinish = useCallback(async function() {
        var role = (await tokenized.post(`/track/${id}`)).data;
        if (role) {
            updateByData(role);
            toast(<BadgeToast role={role} category_id={id} />, {
                position: "top-center",
                closeButton: false,
                autoClose: false
            });
        }
    }, [id, track, updateByData, category]);

    useEffect(function() {
        async function get() {
            const category = await tokenized.get(`/category/${id}`);
            setCategory(category.data);
        }

        get();
    }, [id]);

    if (!category) {
        return null;
    }

    const isCompleted = completed[id];
    const isNext = next_category && category && next_category.id === category.id;

    return <>
            <SetBreadcrumb title={category.name} items={[...all_parents(category)]
                .reverse()
                .map(a => ({
                    title: a.name,
                    link: `/category/${a.id}`
                }))}
            />
        { isCompleted && <Alert color="success"><i className="fa fa-check-circle" />{' '}<FormattedMessage id="MODULE.COMPLETED" values={{ date: <b>{isCompleted.toString()}</b> }} /></Alert> }
        { !isCompleted && !isNext && <Alert color="danger"><i className="fa fa-times-circle" />{' '}<FormattedMessage id={ user.type === 512 ? "MODULE.PREVIOUS_TASK_NEEDED_ADMIN" : "MODULE.PREVIOUS_TASK_NEEDED"} values={{ task: <b><Link to={"/category/" + next_category.id}>{ getModuleName(next_category) }</Link></b> }} /></Alert> }

        { (isCompleted || isNext || user.type === 512) && (category.type === 2 && (
            category.content_type === 1 ?
                <Content value={category.file} onFinish={onFinish} /> :
            category.content_type === 2 ?
                <Video value={category.video} subtitle={category.subtitle && wrap(category.subtitle.access_url)} onFinish={onFinish} /> :
            category.content_type === 3 ?
                <Exam key={category.exam.id} id={category.exam.id} onFinish={onFinish} /> :
            category.content_type === 4 ?
                <ExternalContent title={category.reading_title} link={category.reading_link} onFinish={onFinish} /> :
            category.content_type === 5 ?
                <ExternalVideo url={category.external_video} onFinish={onFinish} /> : 
            category.content_type === 6 ?
                <LearningModule module={category.learning_module} onFinish={onFinish} /> : null))
        }
    </>;
}

export default class extends React.Component {
    render() {
        return <Category key={this.props.match.params.id} id={this.props.match.params.id} />
    }
}
