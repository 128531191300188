import {Row, Col, Card, CardBody, CardHeader, Container, Input} from "reactstrap";
import {Link, Redirect} from "react-router-dom";
import useFetch from "../../fetch";
import {useContext, useState} from "react";
import {UserContext} from "../../UserProvider";
import {Avatar} from "../Chat/Chat";

export default function Hub({ isMentor }) {
    const [ query, setQuery ] = useState('');
    const user = useContext(UserContext);
    const [ users ] = useFetch('/user?networking=true&itemPerPage=-1&query=' + encodeURIComponent(query) + '&' + (isMentor ? 'type=MENTOR' : ''));

    if (!users)
        return null;

    if (!user.metadata)
        return <Redirect to="/network/signup" />;

    return <Container>
        <Card>
            <CardHeader>
                { isMentor ? "Mentoring" : "Networking" } hub
            </CardHeader>
            <CardBody>
                <Input
                    type="text"
                    placeholder="Search by any value"
                    value={query}
                    onChange={a => setQuery(a.currentTarget.value)}
                />
                <div className="user-card-container mt-3">
                    { users.data.map(user => <UserCard key={user.id} user={user} />) }
                </div>
            </CardBody>
        </Card>
    </Container>;
}

function UserCard({ user }) {
    return <div className="user-card">
        <Row className="align-items-center">
            <Col className="col-auto">
                <Avatar user={user} />
            </Col>
            <Col>
                <h1>
                    { user.name }
                    { user.linkedin_profile_url && <a className="ml-1" href={user.linkedin_profile_url} target="_blank" rel="noreferrer">
                        <i className="mdi mdi-linkedin-box" />
                    </a> }
                </h1>
                <h2>{ user.metadata.who_are_you } - { user.metadata.organisation }</h2>
            </Col>
            <Col className="actions col-auto">
                <Link to={"/network/profile/" + user.id}>
                    <i className="mdi mdi-account-circle-outline mr-1" />
                    View full profile
                </Link>
                <Link to={"/chat/" + user.id}>
                    <i className="mdi mdi-message-text-outline mr-1" />
                    Send message
                </Link>
            </Col>
        </Row>
        <ul>
            { user.metadata?.what_are_you_looking_for && <li>
                <b>What are you looking for?</b> { user.metadata.what_are_you_looking_for }
            </li> }
            { user.metadata?.knowledge && <li>
                <b>What knowledge, competence or expertise can you share or offer?</b> { user.metadata.knowledge }
            </li> }
        </ul>
    </div>;
}
