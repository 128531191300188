
import React from 'react';
import { FormattedMessage } from "react-intl";
import { Alert, FormFeedback } from "reactstrap";
import {useValidate, useValidator} from "./ValidatorProvider";

function ValidatorCore(name, value, type, children) {
    const error = useValidate(name || '', value, type);

    return <>
    {(error
            && React.cloneElement(children, { invalid: !!error, className: (children.props.className || '') + (!!error ? ' is-invalid' : '') })) || children}
    {error && <FormFeedback className="d-block">{error}</FormFeedback>}
    </>;
}

export function Validator({ name, type, children }) {
    return ValidatorCore(name, children.props.value || children.props.checked, type, children);
}

export function ValueValidator({ name, value, type, children }) {
    return ValidatorCore(name, value, type, children);
}

export function ValidationErrors() {
    const validator = useValidator();

    if (validator.messagesShown) {
        let errors = Object.values(validator.getErrorMessages()).filter(a => !!a);
        if (!!errors.length) {
            return <Alert color="danger" toggle={() => validator.hideMessages()}>
                <p><i className="fas fa-exclamation-circle" /> <b><FormattedMessage id="VALIDATION.ERROR" /></b></p>
                <ol className="mb-0">
                    { errors.map(value => <li>{ value }</li>) }
                </ol>
            </Alert>;
        }
    }

    return null;
}

